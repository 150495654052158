import {
  AbsoluteCenter,
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { t, Trans } from "@lingui/macro";
import { ButtonLink, LoadingOverlay } from "@src/components/ui-kit";
import { useStore } from "@src/utils/hooks";
import { getQueryParamOptional } from "@src/utils/routing";
import { observer } from "mobx-react-lite";
import { useRouter } from "next/router";
import { Fragment, FunctionComponent, useEffect, useRef } from "react";
import { Field } from "./field";
import { AuthLayout } from "./layout";

export const LoginScreen: FunctionComponent = observer(function LoginScreen() {
  const { authStore } = useStore();
  const router = useRouter();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (router.isReady) {
      const redirect_to = getQueryParamOptional("redirect_to", router.query);
      authStore.initLogin(redirect_to);
    }
  }, [router.isReady]);

  if (authStore.loginForm.state === "error") {
    return (
      <AuthLayout>
        <Alert status="error">
          <Trans>
            Oops, something unexpected happened. Please try refreshing the page.
          </Trans>
        </Alert>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <LoadingOverlay
        isLoading={authStore.loginForm.state === "loading"}
        position="absolute"
      >
        <Stack
          as="form"
          onSubmit={(event) => {
            event.preventDefault();

            // Allow browsers to autofill the form.
            // When browsers autofill this form, they do not trigger the `change` event.
            // Attempt to manually read the value will fail, too, because they don not
            // disclose the value before first user interaction. That's why we use
            // uncontrolled inputs and read the values only after form is submitted,
            // because submission = user interaction.
            if (emailRef.current) {
              authStore.loginForm.email.onChange(emailRef.current.value);
            }
            if (passwordRef.current) {
              authStore.loginForm.password.onChange(passwordRef.current.value);
            }

            authStore.logIn();
          }}
          spacing="4"
        >
          <Heading textAlign="center" size="lg">
            <Trans>Login</Trans>
          </Heading>
          <Text textAlign="center">
            <Trans>Hello there! Glad to have you back. 👋</Trans>
          </Text>
          {authStore.loginForm.redirect_to && (
            <Alert
              display="block"
              whiteSpace="pre-wrap"
              colorScheme="gray"
              rounded="md"
            >
              <Trans>
                After login you will be redirected to:{" "}
                <Link
                  color="#3490dc"
                  whiteSpace="nowrap"
                  href={authStore.loginForm.redirect_to}
                  isExternal
                >
                  {authStore.loginForm.redirect_to}
                </Link>
              </Trans>
            </Alert>
          )}
          <Field
            isInvalid={authStore.loginForm.email.hasError}
            label={t`E-Mail Address`}
            input={
              <Input
                ref={emailRef}
                autoComplete="email"
                autoFocus
                inputMode="email"
                required
                type="email"
              />
            }
            errorMessage={authStore.loginForm.email.error}
          />
          <Field
            isInvalid={authStore.loginForm.password.hasError}
            label={t`Password`}
            input={
              <Input
                ref={passwordRef}
                autoComplete="current-password"
                required
                type="password"
              />
            }
            errorMessage={authStore.loginForm.password.error}
          />
          <HStack alignItems="start" justify="space-between" w="full">
            <Field
              w="auto"
              input={
                <Checkbox
                  isChecked={authStore.loginForm.remember.value}
                  onChange={(event) =>
                    authStore.loginForm.remember.onChange(event.target.checked)
                  }
                >
                  <Trans>Remember Me</Trans>
                </Checkbox>
              }
            />
            <ButtonLink href="/forgot-password" variant="link">
              <Trans>Forgot Your Password?</Trans>
            </ButtonLink>
          </HStack>
          <Button
            colorScheme="violet"
            isLoading={authStore.loginForm.state === "submitting"}
            type="submit"
          >
            <Trans>Login</Trans>
          </Button>
          {authStore.remoteLoginUrl && (
            <Fragment>
              <Box pos="relative" w="full" py="2">
                <Divider />
                <AbsoluteCenter px="6" bg="white">
                  <Text color="gray.500" fontSize="sm" fontWeight="semibold">
                    <Trans>OR</Trans>
                  </Text>
                </AbsoluteCenter>
              </Box>
              <ButtonLink
                colorScheme="violet"
                variant="outline"
                isExternal
                href={authStore.remoteLoginUrl}
              >
                <Trans>Remote login</Trans>
              </ButtonLink>
            </Fragment>
          )}
        </Stack>
      </LoadingOverlay>
    </AuthLayout>
  );
});
