import { VStack } from "@chakra-ui/layout";
import { Card, CardBody } from "@chakra-ui/react";
import authBg from "@src/assets/img/auth-bg.webp";
import allfredLogo from "@src/assets/img/full-logo.svg";
import { FunctionComponent, PropsWithChildren } from "react";

export const AuthLayout: FunctionComponent<PropsWithChildren> = ({
  children,
}) => (
  <VStack
    w="100vw"
    minH="100vh"
    px="4"
    py="12"
    spacing="12"
    bgSize="cover"
    bgRepeat="no-repeat"
    bgPos="center"
    style={{
      backgroundImage: `url(${authBg})`,
    }}
  >
    <img src={allfredLogo} alt="Allfred Logo" width="150" />
    <Card w="450px" maxW="100%" shadow="xl" rounded="xl">
      <CardBody>{children}</CardBody>
    </Card>
  </VStack>
);
