import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { FunctionComponent, ReactNode } from "react";

type FieldProps = {
  isInvalid?: boolean;
  label?: ReactNode;
  input: ReactNode;
  errorMessage?: ReactNode;
} & FormControlProps;
export const Field: FunctionComponent<FieldProps> = ({
  isInvalid,
  label,
  input,
  errorMessage,
  ...props
}) => (
  <FormControl isInvalid={isInvalid} {...props}>
    {label && <FormLabel>{label}</FormLabel>}
    {input}
    {isInvalid && errorMessage && (
      <FormErrorMessage m="0">{errorMessage}</FormErrorMessage>
    )}
  </FormControl>
);
